import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "app-transform-table",
  templateUrl: "./transform-table.component.html",
  styleUrls: ["./transform-table.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TransformTableComponent implements OnInit {
  @Input() data: any[];
  @Input() apps: any[];
  @Output() logsClick = new EventEmitter();
  @Output() execClick = new EventEmitter();
  @Output() editClick = new EventEmitter();
  @Output() cloneClick = new EventEmitter();
  @Output() deleteClick = new EventEmitter();
  cols: any[];
  visible:boolean=false;
  dataValue:any;
  constructor() {
    this.cols = [
      { field: "job_name", header: "job" },
      { field: "job_description", header: "description" },
      // { field: "application_id", header: "application" }
    ];
  }

  ngOnInit(): void {}
  getName(id) {
    try {
      if (this.apps && this.apps.length > 0) {
        return this.apps.find((d) => d.key === id).value.toUpperCase();
      } else {
        throw "";
      }
    } catch {
      return null;
    }
  }

  edit(d) {
   
    const appname=this.getName(d.application_id)
   
    this.editClick.emit({d,app:appname});
  }
  logs(d) {
    this.logsClick.emit(d);
  }
  exec(d) {
    
    this.execClick.emit(d);
  }
  clone(d) {
    this.cloneClick.emit(d);
  }
  delete(d){
    console.log("yes inside delete "+d);
    this.deleteClick.emit(d);
  }
  hidePopup(e){
    this.visible=false;
    this.dataValue=null;

  }

  showPopup(data){
    this.dataValue=data;
    this.visible=true;
  }
 
  actionDo(){
    console.log("yes delete do "+this.dataValue);
    this.delete(this.dataValue)
    this.dataValue=null;
    this.visible=false;
     
  }
  filterData(dt, value) {
   
    dt.filter(this.getIdByName(value), "application_id", "contains");
  }

  getIdByName(name: string) {
    if (this.apps && this.apps.length > 0) {
      const f = this.apps.find(
        (d) => d.value.toLowerCase() === name.toLowerCase()
      );
      if (f) {
        return f.key;
      }
      return "";
    } else {
      return "";
    }
  }
}
