import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TitleService } from "src/app/core/services/title.service";
import { LoaderService } from "src/app/loader/services/loader.service";
import { ToastService } from "src/app/core/services/toast.service";
import { Subscription } from "rxjs";
import { FileService } from "src/app/files/services/file.service";
import { TransformModel, JobsLogModel } from "../../models/tranform.model";
import { FormGroup, FormControl } from "@angular/forms";
import { FormsModel } from "src/app/models/forms.model";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { DataTransferModel } from '../../models/dataTransfer.model';
import { ObservableDataService } from '../../services/observable-data.service';

@Component({
  selector: "app-transform-list",
  templateUrl: "./transform-list.component.html",
  styleUrls: ["./transform-list.component.scss"],
})
export class TransformListComponent implements OnInit {
  activeRoute$: Subscription;
  transformList: TransformModel[] = [];
  visible = false;
  headerForm = new FormGroup({
    client: new FormControl(),
  });
  modalFormModel: FormsModel = null;
  modalForm: FormGroup = null;
  logs: JobsLogModel[] = [];
  selectedJob: any;
  applicationList: any;
  modalHeader: string;
  dataTransferModel:DataTransferModel;
  currentPage:string='/transform-list'
  exeResponse:any;
  constructor(
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private fileService: FileService,
    private loader: LoaderService,
    private toast: ToastService,
    private router: Router,
    public observalbleDataService:ObservableDataService
  ) {}

  ngOnInit(): void {
    this.observalbleDataService.inventoryChanged$.subscribe(
      data=>{
        this.dataTransferModel=data;
        if(this.dataTransferModel.isRedirected===1){
        this.headerForm.get("client").patchValue({
          key: this.dataTransferModel.clientId,
          value: this.dataTransferModel.clientName,
        });
       
        this.getTransformList(this.headerForm.get("client").value.key);
        
       
       
       
      }
      }

    );
    this.setTitle();
    this.getApplications();
  }
  getApplications() {
    this.fileService
      .getAllApplications()
      .subscribe(
        (data) =>
          (this.applicationList = reMapForAutoComplete(
            data,
            "applicationId",
            "name"
          ))
          // (err)=>{
          //  // this.toast.showError(err.error.message,'error');
          // }
      );
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  getTransformList(id) {
    this.loader.showLoader();
    this.logs=[];
    this.fileService.getTransformListByClient(id).subscribe(
      (res) => {
        if (res && res["status"] === 200) {
          this.transformList = res["data"];
        }
        this.loader.hideLoader();
      },
      (err) => {
        this.loader.hideLoader();
        this.toast.showError("Authorization Error", "error");
      }
    );
  }
  getLogs(id) {
    this.logs = [];
    this.loader.showLoader();
    this.fileService.getTransformLogByJob(id).subscribe(
      (data) => {
        this.logs = data;
        this.loader.hideLoader();
      },
      (err) => {
        this.loader.hideLoader();
        this.toast.showError("Authorization error", "error");
      }
    );
  }
  doAction(type) {
    if (type === "yes") {
      if (this.modalHeader.toLowerCase() === "execute") {
        this.executeJob(this.selectedJob.job_id, this.modalForm.value);
      } else {
        this.cloneJob(
          this.headerForm.value.client.key,
          this.selectedJob.job_id,
          this.modalForm.value
        );
      }
    }
    this.visible = false;
  }
  showPopup(data) {
    this.modalHeader = "Execute";
    this.modalFormModel = null;
    this.selectedJob = data;
    const params = JSON.parse(data.job_parameter);
    const keys = Object.keys(params);
    if (keys && keys.length <= 0) {
      this.executeJob(data.job_id);
    } else {
      this.createModelFromParams(params);
      this.visible = true;
    }
  }
  showClonePopup(data) {
    this.visible = true;
    this.modalHeader = "Clone";
    this.selectedJob = data;
    this.modalFormModel = {
      controls: [
        { name: "name", type: "text", label: "Name" },
        {
          name: "application_id",
          type: "dropdown",
          options: this.applicationList,
          label: "Application",
        },
        { name: "description", type: "multiline", label: "Description" },
      ],
    };
  }
  createModelFromParams(params) {
    this.modalFormModel = { controls: [] };
    for (let k in params) {
      this.modalFormModel.controls.push({
        name: k,
        type: k === "asofdate" ? "date" : "text",
        label: k,
        defaultValue: params[k],
      });
    }
  }
  getModalObject(form) {
    this.modalForm = form;
    if (this.modalHeader.toLowerCase() === "clone") {
      this.modalForm.addControl("client", new FormControl());
    }
  }
  executeJob(jobId, params = {}) {
    this.loader.showLoader();
    this.logs = [];
    this.fileService.executeJobById(jobId, params).subscribe(
      (data) => {
        this.exeResponse=data;
        //console.log("transform job :",this.exeResponse.data.data.final_job_status);
       // if (data && data["status"] === 200) {
        if (this.exeResponse && this.exeResponse.data.data.final_job_status === "Success") {
          this.toast.showInfo("Job Status", data["msg"]);         
        } else {         
          this.toast.showError("Execute Error", "Error executing selected job");
        }
        this.loader.hideLoader();
        this.selectedJob = this.modalFormModel = null;       
      },
      (err) => {
        this.loader.hideLoader();
        this.toast.showError("Execute Error", "Error executing selected job");
        this.selectedJob = this.modalFormModel = null;       
      }
    );
  }
  cloneJob(clientId, jobId, params) {
    let valid=false;
    if(params.client!==null && params.application_id!==null && params.description!==null && params.name!==null){
     
      valid=true;
    }
    if(valid){
      this.loader.showLoader();
    const { name, description, application_id } = params;
    clientId=params.client.key;
    this.fileService
      .cloneJobById(clientId, jobId, application_id.key, name, description)
      .subscribe(
        (data) => {
          if (data && data["status"] === 200) {
            this.toast.showInfo("Clone Status", data["msg"]);
            this.getTransformList(this.headerForm.controls.client.value.key)
          } else {
            this.toast.showError("Error", "Error while cloning selected job");
          }
          this.loader.hideLoader();
          this.selectedJob = this.modalFormModel = null;
          
        },
        (err) => {
          this.loader.hideLoader();
          this.toast.showError("Error", "Error while cloning selected job");
          this.selectedJob = this.modalFormModel = null;
          
        }
      );
    }else{
     
      this.toast.showWarn('error','All fields are mandatory');
    }
    
    
   
  }

  navigateToPage(data: any) {
    
    let page='transform';
   
    const fileData = {
      clientId: data.d.client_id,
      applicationId:data.d.application_id,
      clientName:this.headerForm.controls.client.value.value,
      applicationName:data.app,
      jobId:data.d.job_id,
      pageName:this.currentPage
      
    };
    
    this.router.navigate([page], {
      queryParams: { ...fileData },
    });
  }

  deleteJob(data){  
    this.loader.showLoader();
    this.fileService.deleteTransformation(data)
    .subscribe(
      data=>{
        this.toast.showSuccess('Job deleted','success');
        this.getTransformList(this.headerForm.controls.client.value.key)
        this.loader.hideLoader();
      },
      error=>{
        this.loader.hideLoader();
        this.toast.showError('server error','error');
      }
    )
    this.loader.hideLoader();
  }
}
