import { Component, OnInit, ViewChild, SimpleChanges } from '@angular/core';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { FileService } from 'src/app/files/services/file.service';
import { UserService } from 'src/app/user/services/user.service';
import { UserModel } from '../../models/user.model';
import { UserOpsComponent } from 'src/app/user/components/user-ops/user-ops.component';
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';
import { UserSelectorComponent } from 'src/app/userComp/components/user-selector/user-selector.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
   @ViewChild(UserOpsComponent) userOpsComponent;
   @ViewChild(UserSelectorComponent, { read: false, static: false })
   userSelectorComponent: UserSelectorComponent;
  userList: UserModel[] = [];
  editUserValue:UserModel=null;
  activeRoute$:Subscription;
  roleLists:any=[];
  roleId:number;
  constructor(private userService: UserService,private toast:ToastService,private loader:LoaderService,
     private activeRoute: ActivatedRoute, private titleService: TitleService,) { }
  ngOnInit() {
    this.setTitle();
    this.getAllUserList();
    this.getRoleList();
  }
  getAllUserList() {
    this.loader.showLoader();
    this.userService.getAllUserList().subscribe((data) => {
      this.userList = data;
      this.loader.hideLoader();
    }
    ,(err) => {
      this.toast.showError("Authorization Error", "error");
      this.loader.hideLoader();
    }
    
    );
  }
 editUser(data){
  this.getUserById(data.userId);
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
addUser(data){
  let userStatus=0;
  if(data.status==="Active"){
    userStatus=1;
  }
  const userData = {
        userId:this.editUserValue!==null?this.editUserValue.userId:null,
        userName:data.userName,
        firstName:data.firstName,
        lastName:data.lastName,
        status:userStatus,
        phone:this.editUserValue!==null?this.editUserValue.phone:null,
        department:this.editUserValue!==null?this.editUserValue.department:null,
        createdBy:this.editUserValue!==null?this.editUserValue.createdBy:1,
        createdAt:this.editUserValue!==null?this.editUserValue.createdAt:null,
        modifiedBy:this.editUserValue!==null?this.editUserValue.modifiedBy:1,
        modeifiedAt:this.editUserValue!==null?this.editUserValue.modeifiedAt:null,
        roleId:data.roleId!==null?data.roleId.key:null,
        roleName:data.roleId!==null?data.roleId.value:null,
  };
  this.userService
    .saveOrUpdateUser(userData)
    .subscribe((data) => {
      this.userSelectorComponent.userForm.reset();
      this.editUserValue=null;
      this.toast.showSuccess('saved successfully','success');
      this.getAllUserList();
    },
    (err) =>{
      this.toast.showError(err.error.message,"Server Error");
    } 
    ); 
 
}

getUserById(id){
  this.userService.getUserById(id)
  .subscribe(
    data=>{
      this.editUserValue =data;  
    },
    error=>{
      this.toast.showError("Authorization Error",'error');
      
    }
    )
  }

getRoleList(){
  this.userService.getRoleListData().subscribe(
    (data) => {
      this.roleLists=data;
      this.roleLists=this.formatFields(this.roleLists);
      //console.log("user comp role list" +this.roleLists);
    },
    (err) =>{
      this.toast.showError("Authorization Error",'error');
    } 
  );
}

 formatFields(roleList) {
    return reMapForAutoComplete(roleList, "roleId", "roleName");
  }

}
