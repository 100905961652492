import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ClientModel } from "../models/client.model";
import { formatDate } from "src/app/utils/dates";
import { DataTypeModel } from '../models/dataType.model';

@Injectable()
export class ClientService {
  /**
   * @param  {HttpClient} privatehttp
   */
  constructor(private http: HttpClient) {}
  getAllClients() {
    return this.http.get<ClientModel[]>(environment.baseUrl + "/client/getAll");
  }

  getAllDataRtypes() {
    return this.http.get<DataTypeModel[]>(environment.baseUrl + "/client/getAllApplicationFileDataType");
  }

 
}
