import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./user/containers/login/login.component";
import { ErrorPageComponent } from "./error/components/error-page/error-page.component";
import { AngularFireAuthGuard } from "@angular/fire/auth-guard";
import { TransformListComponent } from "./dashboard/pages/transform-list/transform-list.component";
import { TransformComponent } from "./dashboard/pages/transform/transform.component";
import { AuthGuard } from "./user/services/auth.guard";
import { UserComponent } from './dashboard/pages/user/user.component';
import { RolesComponent } from './dashboard/pages/roles/roles.component';
import { AlfredComponent } from "./dashboard/pages/alfred/alfred.component";

const routes: Routes = [

  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  { path: "login", component: LoginComponent, data: { title: "Login" } },
 
  
  {
    path: "transform",
    component: TransformComponent,
    canActivate: [AuthGuard],
    data: { title: "Transform" },
  },
  {
    path: "transform-list",
    component: TransformListComponent,
    canActivate: [AuthGuard],
    data: { title: "Transform List" },
  },
  
  {
    path: "user",
    component: UserComponent,
    canActivate: [AuthGuard],
    data: { title: "User" },
  },
  
  {
    path: "role",
    component: RolesComponent,
    canActivate: [AuthGuard],
    data: { title: "Role" },
  },

  {
    path: "alfred",
    component: AlfredComponent,
    data: { title: "Alfred" },
    canActivate: [AuthGuard],
  },
  {
    path: "regression",
    loadChildren: () =>
      import("./regression/regression.module").then((m) => m.RegressionModule),
  },
  
  {
    path: "sample-pages",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  { path: "**", component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
