import { Component, OnInit, OnDestroy } from "@angular/core";
import { TitleService } from "src/app/core/services/title.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { FormGroup, FormControl } from "@angular/forms";
import { FileService } from "src/app/files/services/file.service";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { environment } from "src/environments/environment";
import { ClientService } from '../../services/client.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { ObservableDataService } from '../../services/observable-data.service';


@Component({
  selector: "app-transform",
  templateUrl: "./transform.component.html",
  styleUrls: ["./transform.component.scss"],
})
export class TransformComponent implements OnInit, OnDestroy {
  activeRoute$: Subscription;
  dataTransferModel:DataTransferModel;
  pageName:string;
  showBackButton:boolean=false;
  headerForm = new FormGroup({
    client: new FormControl(),
    application: new FormControl(),
  });
  envUrl: string = null;
  jobId:any=null;
  applicationList: { key: string | number; value: string }[];
  constructor(
    private titleService: TitleService,
    private activeRoute: ActivatedRoute,
    private fileService: FileService,
    private clientService: ClientService,
    private toast: ToastService,
    public observalbleDataService:ObservableDataService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.setTitle();
    //this.formatUrl();
   
    this.activeRoute$ = this.activeRoute.queryParamMap.subscribe(
      (par) => {
        if (par.has("clientId") && par.has("clientName")) {
          this.headerForm.get("client").setValue({
            key: par.get("clientId"),
            value: par.get("clientName")
          });        
        }
        if ( par.has("applicationId") && par.has("applicationName")) {      
          const applicationData = {
            key: par.get("applicationId"),
            value: "Loan"
          };
          this.headerForm.get("application").setValue(applicationData);
        
      }
      if ( par.has("jobId") ) {
     
        this.jobId= par.get("jobId")   
        
    }
    if(par.has("pageName")){
      this.pageName=par.get("pageName")
      this.showBackButton=true;
    }
    },
      err => {}
    );
    this.formatUrlByRoute();
    this.clientService
      .getAllDataRtypes()
      .subscribe(
        (data) =>{
          (this.applicationList = reMapForAutoComplete(
            data,
            "clientApplicationFileDataTypeId",
            "type"
          ))
        },
        (err)=>
        {
          this.toast.showError("Authorization Error", "error");
        }
      );
     
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  formatUrlByRoute() {
    const { client, application } = this.headerForm.value;
    const id = client && client.key!==null ? client.key : 0;
    const app = application && application.key ? application.key : null;
   const jobid=this.jobId!=null?'/'+this.jobId:""
    const url = `${environment.baseUrl}/etl${jobid}?client_id=${id}&application=${app}&version=1&user=1`;
    this.envUrl = url; 
    console.log(this.envUrl+' env url')
  }

  formatUrl() {
    const { client, application } = this.headerForm.value;
    const id = client && client.key!==null ? client.key : 0;
    const app = application && application.key ? application.key : null;   
    const url =`${environment.baseUrl}/etl?client_id=${id}&application=${app}&version=1&user=1`;
    this.envUrl = url; 
    console.log(this.envUrl+' env url')
  }

  goToPage(){
    if(this.showBackButton){
      this.dataTransferModel={
             clientFileId:null,
             clientId: this.headerForm.controls.client.value.key,
             isRedirected:1,
             applicationId:0,
             toDate:null,
             fromDate:null,
             clientName:this.headerForm.controls.client.value.value,
             page:this.pageName
      }
     // console.log(this.dataTransferModel);
      this.observalbleDataService.addToInventory(this.dataTransferModel);
      this.router.navigate([this.pageName]);
    
    }
  }
 
  ngOnDestroy() {
    this.activeRoute$.unsubscribe();
  }
}
