import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { ToastService } from 'src/app/core/services/toast.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { reMapForAutoComplete } from 'src/app/utils/re-mapper';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss']
})
export class UserSelectorComponent implements OnInit,OnChanges {

  @Input() editUser; 
  @Output() saveUser = new EventEmitter();
  @Input() roleList;
  
  userForm: FormGroup=null;
  enableEdit:boolean=false;
  roleLists:{ key: string | number; value: string }[];
  roleId:number;
  constructor(private toast: ToastService) { 
    this.userForm = new FormGroup({
      userName: new FormControl(null,{ validators: [Validators.required, Validators.email], updateOn: "blur" }),
      firstName: new FormControl(null,Validators.required),
      lastName:new FormControl(null,Validators.required),
      roleId:new FormControl(null,Validators.required),
    });
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    
   
    if (changes && changes.editUser && changes.editUser.currentValue) {
     console.log(changes.editUser.currentValue,' indise changes') 
       const lookUp=changes.editUser.currentValue;
       this.enableEdit = true;
       this.checkForEdit() 
    }
       if (changes && changes.roleList && changes.roleList.currentValue) {
        this.roleLists = this.formatFields(changes.roleList.currentValue);  
        console.log("roles" +this.roleLists)  
   }
    }
    // if (changes && changes.newRule && changes.newRule.currentValue > 1) {
    //   this.resetForm();
    // }
  

  checkForEdit() {
    const RoleData = {
      key: this.editUser.roleId,
      value:this.editUser.roleName,
    };
    if (this.editUser && this.enableEdit) { 
      //console.log("edit user data"+this.editUser)
      this.userForm.patchValue({
        userName: this.editUser.userName,
        firstName: this.editUser.firstName,
        lastName: this.editUser.lastName,
        roleId: RoleData,
      });
      
     
      this.enableEdit = false;
     
    }
  }

  createUser(){
  //console.log("user form "+this.userForm.value)
  if(this.userForm.invalid){
    if(this.userForm.controls.userName.invalid){
      this.toast.showWarn('username mandatory & should be in email format','error');
      return;
    }
    this.toast.showWarn('All fields are mandatory ','error');
    return;
  }
  
    this.saveUser.emit(this.userForm.value)

  }

  formatFields(roleList) {
    return reMapForAutoComplete(roleList, "roleId", "roleName");
  }
  
}
