import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";

import { FileService } from "./services/file.service";
import { TransformLogComponent } from "./components/transform-log/transform-log.component";
import { TransformTableComponent } from "./components/transform-table/transform-table.component";


@NgModule({
  declarations: [
   
    TransformLogComponent,
    TransformTableComponent,
    
  ],
  imports: [SharedModule],
  exports: [
   
    TransformLogComponent,
    TransformTableComponent,
    

  ],
  providers: [FileService]
})
export class FilesModule {}
