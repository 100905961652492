import { NgModule } from "@angular/core";

import { DashboardRoutingModule } from "./dashboard-routing.module";
import { MainPageComponent } from "./pages/main-page/main-page.component";
import { SharedModule } from "../shared/shared.module";
import { UiModule } from "../ui/ui.module";
import { ExcelModule } from "../excel/excel.module";

import { TransformComponent } from "./pages/transform/transform.component";

import { ClientService } from "./services/client.service";

import { FilesModule } from "../files/files.module";


import { TransformListComponent } from "./pages/transform-list/transform-list.component";



import { UserComponent } from './pages/user/user.component';
import { UserModule } from '../user/user.module';
import {UserCompModule} from '../userComp/userComp.module';
import { DatePipe } from '@angular/common';
import { RolesComponent } from './pages/roles/roles.component';
import { DualListModule } from '../roles-dual/roles-dual.module';
import { AlfredLogsComponent } from './components/alfred-logs/alfred-logs.component';
import { AlfredComponent } from "./pages/alfred/alfred.component";



@NgModule({
  declarations: [
    MainPageComponent,  
    TransformComponent, 
    TransformListComponent,   
    UserComponent,
    RolesComponent,
    AlfredComponent,
    AlfredLogsComponent,
   
  ],
  imports: [
    SharedModule,
    UiModule,
    //DashboardRoutingModule,
    ExcelModule,
    FilesModule,
    UserCompModule,
    DualListModule
    
  ],
  providers: [ClientService,DatePipe]
})
export class DashboardModule {}
