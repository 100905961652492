import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { take, map } from "rxjs/operators";
import { Store } from "@ngrx/store";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store<{ user: any }>, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      return this.store.select("user").pipe(
        take(1),
        map((u) => {
          if (!u) {
            this.router.navigate(["/login"], {
              queryParams: { returnUrl: state.url },
            });
          }
          return !!u;
        })
      );
    }
}
