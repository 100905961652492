import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ActionModel } from '../models/action.model';
import { RoleModel } from '../models/role.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private http: HttpClient) { }


  saveAllActions(ActionData) {
  return this.http.post<ActionModel[]>(environment.baseUrl + "/users/saveAllAssignedActions",ActionData);
  }

  saveRole(roleData){
    return this.http.post<RoleModel[]>(environment.baseUrl + "/users/saveRole",roleData);
  }

}
