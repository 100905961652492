import { Action, createReducer, on } from "@ngrx/store";
import { storeUser, signOut, updateUser } from "./auth.action";
export const featureKey = "user";

export const initialState: any = null;

const _authReducer = createReducer(
  initialState,
  on(storeUser, (state, { data }) => data),
  on(updateUser, (state, { data }) => ({ ...state, ...data })),
  on(signOut, (state) => null)
);

export function authReducer(state: any, action: Action) {
  return _authReducer(state, action);
}
