import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { FormControl, Validators, FormGroup } from "@angular/forms";
import { differenceWith, isEqual } from "lodash";
import { FormsModel } from "src/app/models/forms.model";
import { ActivatedRoute } from '@angular/router';
import { TitleService } from 'src/app/core/services/title.service';
import { Subscription } from "rxjs";
import { RoleService } from '../../services/role.service';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  roles: { key: string | number; value: string }[];
  rolesCtrl = new FormControl(null);
  sourceData: { key: string | number; value: string }[];
  targetData: { key: string | number; value: string }[];
  masterSourceData: Object;
  roleLabel:string="Select Role";
  activeRoute$: Subscription;
  addRolePopup: boolean = false;
  actionList:any=[];
  roleId:any;
  newRoleModel: FormsModel = {
    controls: [
      {
        name: "roleName",
        type: "text",
        validators: [Validators.required],
        label: "Role Name",
      },
      {
        name: "roleDescription",
        type: "text",
        validators: [],
        label: "Role Description",
      },
      
    ],
  };
  roleForm: FormGroup;
  constructor(private http: HttpClient,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private rolesService: RoleService,
    private toast: ToastService) { }

  ngOnInit(): void {
    this.setTitle();
    this.http
    .get(`${environment.baseUrl}/users/getAllRoles`)
    .subscribe((res) => {
      this.roles = reMapForAutoComplete(res as any[], "roleId", "roleName");
    });
  this.http
    .get(`${environment.baseUrl}/users/getAllActions`)
    .subscribe((res) => {
      this.masterSourceData = res;
    });
  }

  getAllRules(data) {
    console.log(data);
    // this.http.get(`${environment.baseUrl}/users/getAllRoles`);
   this.roleId=data.key;
    this.http
      .post(`${environment.baseUrl}/users/getAllActionsByRoleId`, {
        roleId: data.key,
      })
      .subscribe((res) => {
        this.targetData = reMapForAutoComplete(
          res as any[],
          "actionId",
          "actionGroup"
        );
        this.loadMissingToSource();
      });
  }
  loadMissingToSource() {
    const sourceData = reMapForAutoComplete(
      this.masterSourceData as any[],
      "actionId",
      "actionGroup"
    );
    this.sourceData = differenceWith(sourceData, this.targetData, isEqual);
  }
  getSelectedData(data) {
    console.log("role id" +this.roleId);
    const actionListData=data;
    for(var a=0;a<actionListData.length;a++){
        this.actionList.push(actionListData[a].value);
      }
    const ActionData={
     "roleId":this.roleId,
     "actionGroup":this.actionList

    }
    this.rolesService.saveAllActions(ActionData).subscribe(
      (data) => {
        this.actionList=[];
        this.toast.showSuccess("Action SuccesFully Saved", "success");
      },
      (err) =>{
        this.toast.showError(err.error.message, "error saving data");
      } 
    );
  }

  showPopup() {
    this.addRolePopup = true;
  }
  doAction(type: string) {
    if (type.toLowerCase() === "yes") {
      this.roleForm.reset();
    }
    this.addRolePopup = false;
    this.roleForm.reset();
  }
  getForm(form: FormGroup) {
    this.roleForm = form;
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  addRole(){
    console.log(this.roleForm.controls.value)
    const roleData={
      "roleName":this.roleForm.controls.roleName.value,
      "roleDescription":this.roleForm.controls.roleDescription.value
    }
    this.rolesService.saveRole(roleData).subscribe(
      (data) => {
        this.addRolePopup = false;
        this.roleForm.reset();
        this.getRoles();
        this.toast.showSuccess("Role SuccesFully Saved", "success");
      },
      (err) =>{
        this.toast.showError(err.error.message, "error");
      } 
    );
  }

  getRoles(){
    this.http
    .get(`${environment.baseUrl}/users/getAllRoles`)
    .subscribe((res) => {
      this.roles = reMapForAutoComplete(res as any[], "roleId", "roleName");
    },
    (err)=>{
      this.toast.showError(err.error.message, "error");
    }
    );
  }
}
