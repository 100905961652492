import { Component, 
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-dual-list',
  templateUrl: './dual-list.component.html',
  styleUrls: ['./dual-list.component.scss']
})
export class DualListComponent implements OnInit, OnChanges {
  @Input() source: { key: any; value: any }[] = [];
  @Input() sourceHeaderText = "Available";
  @Input() boxHeight = 300;
  @Input() filterBy: string = "value";
  @Input() target: { key: any; value: any }[] = [];
  @Input() targetHeaderText = "Selected";
  @Input() btnText = "Save";
  targetData: { key: any; value: any }[] = [];
  @Output() selectedValues = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.target && changes.target.currentValue) {
      this.targetData = changes.target.currentValue;
    }
  }

  emitValues() {
    this.selectedValues.emit(this.targetData);
  }

}
