import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { UserService } from 'src/app/user/services/user.service';


@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent implements OnInit {

  @Input() data = [];
  @Output() edit = new EventEmitter();
  userList:any[];

  cols: { field: string; header: string }[];
  constructor(
    private loader:LoaderService,
    private userService:UserService
  ) {

    this.cols = [
      { field: "userName", header: "User Name" },
      { field: "firstName", header: "Firs tName" },
      { field: "lastName", header: "Last Name" },
      { field: "roleName", header: "Role Name" },
      // { field: "status===1?Active:InActive", header: "Status" }
    ];
   }

  ngOnInit(): void {
  }

  editUser(data) {
    console.log("I am in edit");
    console.log(data);

    this.edit.emit(data);
  }

}
