import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { CategoryService } from "../../services/category.service";
import { reMapForAutoComplete } from "src/app/utils/re-mapper";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TitleService } from "src/app/core/services/title.service";
import { ToastService } from "src/app/core/services/toast.service";
import { LogsModel } from "src/app/models/logs.model";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
@Component({
  selector: "app-alfred",
  templateUrl: "./alfred.component.html",
  styleUrls: ["./alfred.component.scss"],
  providers: [DatePipe],
  // encapsulation: ViewEncapsulation.None,
})
export class AlfredComponent implements OnInit {
  parentCats = [];
  subCats1 = [];
  subCats2 = [];
  series = [];
  frequency: any[] = [
    { key: "d", value: "Daily" },
    { key: "w", value: "Weekly" },
    { key: "bw", value: "Bi-weekly" },
    { key: "m", value: "Monthly" },
    { key: "q", value: "Quarterly" },
    { key: "sa", value: "Semi-Annual" },
    { key: "a", value: "Annual" },
  ];
  transformation: any[] = [
    { key: "lin", value: "No Transformation" },
    { key: "chg", value: "Change" },
    { key: "ch1", value: "Change from Year Ago" },
    { key: "pch", value: "Percent Change" },
    { key: "pc1", value: "Percent Change from Year Ago" },
    { key: "pca", value: "Compounded Annual Rate of Change" },
    { key: "cch", value: "Continuously Compounded Rate of Change" },
    { key: "cca", value: "Continuously Compounded Annual Rate of Change" },
  ];
  aggregation = [
    { key: "avg", value: "Average" },
    { key: "sum", value: "Sum" },
    { key: "eop", value: "End of Period" },
  ];
  searchBy = new FormControl("category");
  filterForm = new FormGroup({
    parentCat: new FormControl(null),
    subCat1: new FormControl(null),
    subCat2: new FormControl(null),
    series: new FormControl(null, [Validators.required]),
    startDate: new FormControl(null),
    endDate: new FormControl(null),
    asOfDate: new FormControl(null),
    frequency: new FormControl(null),
    transformation: new FormControl(null),
    aggregation: new FormControl(null),
    batchName: new FormControl(null),
  });
  filterDates = new FormGroup({
    startDate: new FormControl(moment().startOf("month").toDate()),
    endDate: new FormControl(moment().endOf("month").toDate()),
    batchName: new FormControl(null),
  });
  activeRoute$: any;
  variables: string;
  dataLogs: LogsModel[];
  vintageDates: any[] = [];
  filteredFrequencies: any[] = [];
  deleteStatus:any;

  constructor(
    private catService: CategoryService,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private toast: ToastService,
    private datePipe: DatePipe
  ) {}
  ngOnInit(): void {
    this.getCategoriesById(0, "parentCats");
    this.setTitle();
    this.getLogs();
  }
  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }
  getCategoriesById(id, type) {
    if (type === "subCats1") {
      this.subCats1 = this.subCats2 = this.series = [];
      const data = { key: "", value: "" };
      this.filterForm.patchValue({
        subCat1: data,
        subCat2: data,
        series: data,
      });
      this.filterForm.get("asOfDate").reset();
      this.filterForm.get("frequency").reset();
    }
    this[type] = [];
    this.catService.getCategories(id).subscribe(
      (res: any) => {
        if (res && res.items && res.items.length > 0) {
          this[type] = reMapForAutoComplete(
            res["items"] as any[],
            "id",
            "text"
          );
        }
        if (type === "subCats2" && res.items.length <= 0) {
          this.getSeries(id);
        }
      },
      (e) => {}
    );
  }
  getSeries(id) {
    const { subCat1, parentCat } = this.filterForm.value;
    this.catService.getSeries(id, parentCat.key, subCat1.key).subscribe(
      (res) => {
        this.series = res["items"].map((d) => ({
          key: d.id,
          value: `${d.text} (${d.id})`,
        }));
        //this.series = reMapForAutoComplete(res["items"] as any[], "id", "text");
      },
      (e) => {}
    );
  }
  getSeriesMeta(id) {
    this.vintageDates = this.filteredFrequencies = [];
    this.filterForm.get("asOfDate").reset();
    this.filterForm.get("frequency").reset();
    if (id && id !== "") {
      this.catService.getSeriesMeta(id).subscribe((res: any) => {
        if (res && res.data)
          if (res.data.dates && res.data.dates.length > 0) {
            this.vintageDates = res.data.dates.map((d) => ({
              key: d,
              value: this.datePipe.transform(d, "MM/dd/yyyy"),
            }));
          }
        if (res.data.frequency && res.data.frequency.length > 0) {
          this.filteredFrequencies = this.frequency.filter((d) =>
            res.data.frequency.includes(d.key)
          );
        }
      });
    }
  }
  reFormatData() {
    const format = "yyyy-MM-dd";
    const {
      series,
      startDate,
      endDate,
      asOfDate,
      frequency,
      transformation,
      aggregation,
      batchName,
    } = this.filterForm.value;
    const data = {
      peer_type: batchName,
      variable: series && series.key !== "" ? series.key : null,
      observation_end: this.datePipe.transform(endDate, format),
      observation_start: this.datePipe.transform(startDate, format),
      // vintage_date: this.datePipe.transform(asOfDate, format),
      vintage_date: asOfDate && asOfDate.key !== "" ? asOfDate.key : null,
      frequency: frequency && frequency.key !== "" ? frequency.key : null,
      units:
        transformation && transformation.key !== "" ? transformation.key : null,
      aggregation_method:
        aggregation && aggregation.key !== "" ? aggregation.key : null,
    };
    if (this.searchBy.value === "series") {
      data.variable = this.variables;
    }
    return data;
  }
  getLogs() {
    const data = {
      ...this.reFormatData(),
      start_date: this.datePipe.transform(
        this.filterDates.value.startDate,
        "yyyy-MM-dd"
      ),
      end_date: this.datePipe.transform(
        this.filterDates.value.endDate,
        "yyyy-MM-dd"
      ),
      batch_name: this.filterDates.value.batchName,
    };
    this.catService.dataLogs(data).subscribe(
      (logs) => {
        this.dataLogs = logs;
      },
      (e) => {
        this.toast.showError("Log Error", "There was an error fetching logs");
      }
    );
  }
  dataImport() {
    const data = this.reFormatData();
    console.log(data);
    if (data.variable === "" || data.variable === null) {
      this.toast.showError("No Series", "Please select a series");
    } else {
      this.catService.dataImport(data).subscribe(
        (res) => {
          this.toast.showSuccess(
            "Operation In Process",
            "Please check log for details after sometime."
          );
          this.getLogs();
        },
        (e) => {
          this.toast.showError("Log Error", "There was an error fetching logs");
        }
      );
    }
  }
  loadQuery(data: LogsModel[]) {
    this.filterForm.reset();
    this.searchBy.setValue("series");
    this.getSeriesMeta(data[0].series_id);
    const series = data.map((d) => d.series_id);
    this.variables = series.toString();
    console.log(data);
    let frequency = null;
    if (data[0].frequency && data[0].frequency !== "") {
      const f = this.frequency.find((d) => d.key === data[0].frequency);
      if (f) {
        frequency = f;
      }
    }
    let aggregation = null;
    if (data[0].aggregation_method && data[0].aggregation_method !== "") {
      const f = this.aggregation.find(
        (d) => d.key === data[0].aggregation_method
      );
      if (f) {
        aggregation = f;
      }
    }
    let transformation = null;
    if (data[0].units && data[0].units !== "") {
      const f = this.transformation.find((d) => d.key === data[0].units);
      if (f) {
        transformation = f;
      }
    }
    const asOfDate =
      data[0].vintage_date && data[0].vintage_date !== ""
        ? data[0].vintage_date
        : null;
    const value = {
      startDate:
        data[0].observation_start && data[0].observation_start !== ""
          ? data[0].observation_start
          : null,
      endDate:
        data[0].observation_end && data[0].observation_end !== ""
          ? data[0].observation_end
          : null,
      asOfDate: {
        key: asOfDate,
        value: this.datePipe.transform(asOfDate, "MM/dd/yyyy"),
      },
      frequency,
      aggregation,
      transformation,
      batchName: data[0].batch_name !== "null" ? data[0].batch_name : "",
    };
    this.filterForm.patchValue(value);
  }
  updateFilterDates(dates) {
    this.filterDates = dates;
  }
  getSeriesFromVariable() {
    const series = this.variables.split(",");
    this.getSeriesMeta(series[0]);
  }
  deleteBatch(data){
      this.deleteBatchByBatchId(data);
  }

  deleteBatchByBatchId(data){
    const batchId=data.batch_id;
    this.catService.deleteBatchByBatchId(batchId)
    .subscribe(
      data=>{
        this.deleteStatus=data;
        //console.log('delete status ',this.deleteStatus);
        if(this.deleteStatus.status===200){
          this.toast.showSuccess('success','batch deleted successfully')
          this.getLogs();
        }else{
          this.toast.showSuccess('error','error')
        }
        
      }
    )
  }
}
