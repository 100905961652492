import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { ClientModel } from "src/app/dashboard/models/client.model";
import { environment } from "src/environments/environment";

import { formatDate } from "src/app/utils/dates";
import {
  TransformModel,
  JobsLogModel,
  TransformDataModel,
} from "src/app/dashboard/models/tranform.model";


@Injectable()
export class FileService {
  /**
   * @param  {HttpClient} privatehttp
   */
  constructor(private http: HttpClient) {}
 
  
 
 
  
  /**
   * @param  {number} id
   */
  getTransformListByClient(id: number) {
    var payload = new FormData();
    payload.append("client_id", id.toString());
    return this.http.post<any>(
      `${environment.baseUrl}/api/etl/list/`,
      payload
    );
  }

  deleteTransformation(data){
    const body = new HttpParams()
      .set(`client_id`, data.client_id)
      .set(`id`, data.job_id)
      .set(`user_id`,"1")   
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const api = `${environment.baseUrl}/api/etl/delete/`;
    return this.http.post(api, body.toString(), { headers });
  }

  getAllApplications() {
    return this.http.get<any[]>(
      `${environment.baseUrl}/client/getAllApplication`
    );
  }

  cloneJobById(clientId, jobId, appId, name, desc) {
    const body = new HttpParams()
      .set(`client_id`, clientId)
      .set(`transformation_id`, jobId)
      .set("name", name)
      .set("description", desc)
      .set("application_id", appId);
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const api = `${environment.baseUrl}/api/etl/clone/`;
    return this.http.post(api, body.toString(), { headers });
  }

  getTransformLogByJob(id: number) {
    return this.http.get<JobsLogModel[]>(
      `${environment.baseUrl}/client/getTransformLogByJobId/${id}`
    );
  }

  executeJobById(id, job_parameters) {
    const body = new HttpParams()
      .set(`id`, id)
      .set(`job_parameters`, JSON.stringify(job_parameters));
    const headers = new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    });
    const api = `${environment.baseUrl}/api/etl/transformation/`;
    return this.http.post(api, body.toString(), { headers });
  }
 
 
  
  


 

  
 

    

  
   

    

   
}
