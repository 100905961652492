import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs'
import { DataTransferModel } from '../models/dataTransfer.model';

@Injectable({
  providedIn: 'root'
})
export class ObservableDataService {

  dataTransferModel:DataTransferModel={
    clientFileId:0,
    clientId:0,
    isRedirected:0,
    applicationId:0,
    toDate:'',
    fromDate:'',
    clientName:'',
    page:''
}

private inventorySubject$=new BehaviorSubject<DataTransferModel>(this.dataTransferModel);
inventoryChanged$=this.inventorySubject$.asObservable();

constructor() { }

addToInventory(dataTransferModelData:DataTransferModel){
  this.dataTransferModel=dataTransferModelData;
  this.inventorySubject$.next(dataTransferModelData);
}
}
